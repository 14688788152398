define([
    "./trackingModule",
    "lodash"
], function (trackingModule, _) {
    "use strict";

    trackingModule.controller("TrackingController", [
        "$scope",
        "routeService",
        "loadingService",
        "remoteExceptionHandler",
        "truckMarkerPopupCreator",
        "trackingService",
        "$q",
        "trackingMonitorableList",
        "messagesModal",
        "$timeout",
        "monitoringService",
        function ($scope, routeService, loadingService, remoteExceptionHandler, truckMarkerPopupCreator, trackingService, $q, trackingMonitorableList, messagesModal, $timeout, monitoringService) {
            var routeApi;

            $scope.serviceName = monitoringService.getMonitorableSearchUrl();
            $scope.markerControl = {};
            $scope.model = {
                routes: [],
                markers: []
            };
            $scope.show = {orphanSignals: true};

            $scope.search = function (modifiedTemplate) {
                $scope.moritoringTripIdMap = {};
                $scope.template = modifiedTemplate;
                retrieveTripsAndCreateMarkers(modifiedTemplate);
            };
            function retrieveTripsAndCreateMarkers(modifiedTemplate) {
                clearMap();
                var promises = [];
                promises.push(monitoringService.filterMonitorableTrackingSummary(modifiedTemplate));
                if ($scope.show.orphanSignals) {
                    promises.push(trackingService.getLatestOrphanSignals());
                }

                loadingService($q.all(promises)).then(function (filterResult) {
                    var monitorableTrackingSummary = filterResult[0].result;
                    monitorableTrackingSummary.map(function (monitorable) {
                        return createMarkers(monitorable);
                    });

                    if (filterResult[1]) {
                        var latestOrphanSignals = filterResult[1];
                        latestOrphanSignals.map(function (signal) {
                            return createMarkersFromSignals(signal);
                        });
                    }
                    routeApi.centerOnTrucks($scope.model.markers);
                }).catch(remoteExceptionHandler());
            };


            function createMarkersFromSignals(signal) {
                let popupContent = {
                    markerId: signal.deviceId + signal.providerId, //para evitar clash com o id dos monitoráveis
                    providerId: signal.providerId,
                    deviceId: signal.deviceId,
                    lastPosition: signal,
                    truckIcon: signal.old ? 'LATE' : '',
                    isOrphanSignal: true
                };
                $scope.model.markers.push(truckMarkerPopupCreator(popupContent));
            }

            function createMarkers(summary) {
                var destination = null;
                var nextTransition = summary.nextTransition;
                if (nextTransition && nextTransition.location && nextTransition.location.locality) {
                    var locality = nextTransition.location.locality;
                    destination = [locality.street, locality.number, locality.district, locality.city].filter(function (value) {
                        return !!value;
                    }).join(", ");
                }

                let popupContent = {
                    markerId: summary.id,
                    monitorableCode: summary.sourceId,
                    type: summary.type,
                    vehicle: summary.vehicle ? summary.vehicle.name : "",
                    driver: summary.currentDriver,
                    currentPlate: summary.currentPlate,
                    lastPosition: summary.lastPosition,
                    lastTransition: summary.lastTransition,
                    destination: destination,
                    nextTransition: nextTransition,
                    nextTransitionTime: nextTransition ? nextTransition.expectedTimestamp : null,
                    children: summary.children,
                    hasPendingOccurrences: summary.hasPendingOccurrences,
                    truckIcon: summary.positionStatusIcon,
                    isOrphanSignal: false
                };

                $scope.model.markers.push(truckMarkerPopupCreator(popupContent));
            }

            function clearMap() {
                if ($scope.markerControl.hideMarkerPopup) {
                    $scope.markerControl.hideMarkerPopup();
                }
                $scope.model.markers = [];
                $scope.model.routes.forEach(function (route) {
                    //dispara watch em mapsRendererService
                    route.visible = false;
                });
            }

            $scope.refresh = function () {
                if ($scope.template) {
                    retrieveTripsAndCreateMarkers($scope.template);
                }
            };

            $scope.onRegisterRouteApi = function (api) {
                routeApi = api;
            };

            $scope.inspect = function (result) {
                let tripEnum = "br.com.neolog.monitoring.monitorable.model.api.StandardMonitorableType.TRIP";
                let monitorable = {
                    id: result.id,
                    sourceId: result.trip,
                    type: result.type  === tripEnum ? "Trip" : ""
                };

                let listMonitorables = [monitorable];
                result.children.forEach(function (children) {
                    let c = {
                        id: children.id,
                        sourceId: children.sourceId,
                        type: children.typeName
                    };
                    listMonitorables.push(c);
                });

                trackingMonitorableList(listMonitorables);
            };
        }]);
});